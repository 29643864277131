import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import loaderImage from "../assets/assets/images/loader1.gif";

function Container(props) {
  const [responseData, setResponseData] = useState(null);
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const apiUrl = process.env.REACT_APP_API_URL;
  
  useEffect(() => {
    // Retrieve username from session storage
    const usernameFromSession = sessionStorage.getItem('username');
   
    if (usernameFromSession) {
        const userId = usernameFromSession.split('_')[0];
        setUsername(userId);
        // setUsername(usernameFromSession);
    } else {
        // Redirect or handle the case when the username is not available in session
        history.push('/login'); // Redirect to login page
    }
   
  }, []);

  useEffect(() => {
    setLoading(true);
    const fullUsername = sessionStorage.getItem('username');
    if (fullUsername) {
      const username = fullUsername.split('_')[0]; // Extract only the first part of the username
      const url = `${apiUrl}/chat/submit/${username}/prediction`;
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        // Add any body data if needed
        body: JSON.stringify({ key: 'value' }),
      };

      // Make the POST API call
      fetch(url, requestOptions)
        .then((response) => {
          if (response.ok) {
            setLoading(false);
            return response.json();
          }
          throw new Error('Network response was not ok');
        })
        .then((data) => {
            setLoading(false);
          setResponseData(data);
        })
        .catch((error) => {
            setLoading(false);

          console.error('Error fetching data:', error);
        });
    } else {
      // Handle the case when username is not available in session
      history.push('/login');
    }
  }, [apiUrl, history]);

  // Render the response data in JSX
  return (
    <div>
        	{loading && (
<div className="loader-overlay">
    
        <img className="imageloader" src={loaderImage} alt="Loader" />
    
</div>
)}

     
      {responseData ? (
        <div>
          <h2 style={{ color: 'blue' }}>Total: {responseData[username].Total}</h2>
          <h3 style={{ color: 'green' }}>Breakdown:</h3>
          <ul>
            {responseData[username].BreakDown.map((item, index) => (
              <li key={index}>
                <strong style={{ color: 'red' }}>Naturalness:</strong> {item.naturalness}<br />
                <strong style={{ color: 'orange' }}>Coherence:</strong> {item.coherence}<br />
                <strong style={{ color: 'purple' }}>Understandability:</strong> {item.understandability}<br />
                <strong style={{ color: 'brown' }}>Overall:</strong> {item.overall}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return state.reducer;
};

export default connect(mapStateToProps, null)(Container);
