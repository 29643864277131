

import React, { Children, cloneElement, Fragment, isValidElement, PureComponent, useEffect, useRef, useState, useCallback } from "react";
import ReactDOM from 'react-dom';
import { connect } from "react-redux";
import loaderImage from "../assets/assets/images/loader1.gif";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import { get } from 'lodash';
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify'; // Import the entire module
import 'react-toastify/dist/ReactToastify.css';
import { Area, Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { 
	Chart, PieChart, RichTextEditor, RichText, 
	ToggleWrapper, Tabs, Slider, Form, MountHandler,
	Draggable, ErrorBoundary, toISOString, 
	toLocalString, ZoomablePannable, Progress, RangeSlider,
	getFileUrl, Select, MapBox, Marker, DynamicComponent
} from "../components/dittofi-components";

function Container(props) {
	let {
		dispatch,
	} = props;
	const apiUrl = process.env.REACT_APP_API_URL;
	const [tasks, setTasks] = useState({});
	// const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
    const history = useHistory();
	const [loading, setLoading] = useState(false)

	const handleSignUp = () => {
        // Redirect to home page
        history.push('/');
    };
    
	const handleLogin = () => {
		setLoading(true);
		const formData = new URLSearchParams();
		formData.append('username', email); // Assuming email is used as the username
		formData.append('password', password);
		
		// Make a POST request to the login endpoint
		fetch(`${apiUrl}/login`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: formData.toString(),
		})
		.then(response => {
			if (response.ok) {
				// If the response is successful, extract the user's ID and store it in the session
				return response.json();
			} else {
				// If the response is not successful, handle the error
				console.error('Login failed');
				// You can display an error message to the user
				throw new Error('Login failed');
			}
		})
		.then(data => {
			// Extract the user's ID from the response and store it in the session
			const userId = data.id;
			 // Concatenate username and timestamp
			 const loginInfo = `${userId}_${new Date().getTime()}`;

			 // Store concatenated string in session
			 sessionStorage.setItem('username', loginInfo);
			// sessionStorage.setItem('username', userId);
			setLoading(false);
			
			// Redirect to /chat
			history.push("/info");
		})
		// .catch(error => {
		// 	console.error('Error:', error);
		// 	setLoading(false)
		// 	// Handle other errors here
		// });
		.catch(error => {
            console.error('Error:', error);
            setLoading(false);
            toast.error('Login failed. Please check your credentials and try again.');
        });
	};
	
    
    
	
	var startPoll = (action) => {
	    console.log(`Starting poll for action ${action.name}`);

	    // Prevent polling the same action more than once.
	    if(tasks[action.name]) {
	        console.warn(`You're attempting to poll ${action.name} but it is already being polled.`);
	        return;
	    }
	    
	    // Handle stopping poll.
	    let canceled = false;
	    const cancel = () => canceled = true;

	    // Poll action.
	    const poll = () => {
	        console.log(`Polling action ${action.name}`);

	        if(canceled) {
	            return;
	        }

	        dispatch({ 
		        type: action.name, 
		        history: history, 
		        inputVariables: action.payload ? action.payload : {}
	        });
	        
	        setTimeout(() => poll(), action.pollingPeriod);
	    }

	    setTasks({...tasks, [action.name]: cancel});
	    poll();
	}

	var stopPoll = (actionName) => {	    
	    console.log(`Stopping poll for action ${actionName}`);

	    // Check task exists.
	    if(!tasks[actionName]) {
	        console.warn(`You're attempting to stop polling ${actionName} but this action is not running.`);
	        return;
	    }

	    tasks[actionName]();
	}

	var runAction = (action) => {
		if(action.pollingPeriod && action.pollingPeriod > 0) {
			startPoll(action);
		} else {
			dispatch({
				inputVariables: action.payload ? action.payload : {},
				params: { 
				},
				history: history,
				type: action.name,
				timeout: action.timeout && action.timeout > 0 ? action.timeout : null,
			});
		}
	}

	useEffect(() => {
		return () => {
			Object.keys(tasks).forEach((t) => stopPoll(t));
		}
	}, [tasks]);

	useEffect(() => {
		window.scrollTo(0, 0);
		/* TODO - run sagas on page mount (be sure not to rerun in page parameter change hook) */
	}, []);

	return (
		<ErrorBoundary>
			   <ToastContainer />
			
				
	<meta charset="utf-8"
	>
	</meta>

			
				
	<link href="globals.css" rel="stylesheet"
	>
	</link>

			
				
	<link href="styleguide.css" rel="stylesheet"
	>
	</link>

			
				
	<link href="style.css" rel="stylesheet"
	>
	</link>

			
				
	<div className="log-in" style={{"font-family": "system-ui", " font-size": "16px"}}
	>
			
	<div className="section"
	>
			
	<div className="container"
	>
		 
			
	<div className="content" style={{"marginLeft":"0px"}}
	>
			
	<div className="div"
	>
			
	<img style={{"margin-left":"2.3em"}}className="Image_1" id="il0j3" src="https://dittofipublicfiles.s3.us-west-2.amazonaws.com/7473/30010-Radiant-Logo-lg.jpg"
	>
	</img>

			
	<div className="text"
	style={{
		"background": "#044856",
		"color": "white",
		"text-align": "center",
		"border-radius": "5px",
		"box-shadow":" rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
		"height": "30px"
		}}
	>
			Welcome to RAG
	</div>

			
	<div className="supporting-text"
	>
			{/* Please enter your details. */}
	</div>

	</div>

			
	<div className="frame"
	>
			
	<div className="button-group"
	>
			
	<div className="button-group-base"
	style={{"background":"#044856"}}
	>
			
	<div className="text-wrapper"
	style={{"color":"white"}}
	>
			User
	</div>

	</div>

			
	<div className="button-group-base" style={{   
		"border-left": "1px solid",
       "background": "rgb(186 193 200 / 60%)"
	}}
	>
			
	<div className="text-wrapper" 
	>
			Admin
	</div>

	</div>

	</div>

			
	<div className="content-2"
	>
			
	<div className="form"
	>
		{/* <label className="label-2" for="input-3"
	>
			UserName
	</label> */}
		    {/* <div className="div-2">
                                    <div className="div-2 input">
                                        <input
										style={{
											"width": "100%",
											"outline-color": "rgb(15 23 40 / 34%)"
										}}
                                            className="label"
                                            placeholder="Enter User name"
                                            type="text"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                        />
                                    </div>
                                </div> */}
			
	<div className="div-2"
	>
			
	<div className="div-2"
	>
		<label className="label-2" for="input-2"
	>
		Email Id
	</label>	
	{/* <input className="label"
                                            placeholder="Enter User Email"
                                            type="email"
                                            value={email}
                                            onChange={(e) => setUsername(e.target.value)}
	>
	</input> */}

			
	<div className="input"
	style={{
		"padding": "0px",
		"height": "40px",
		"border-radius": "0px",
		"border": "1px solid #00000075",
	

	}}
	>
			
	<input className="content-3" placeholder="Enter Email Id"
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
											style={{
												"width": "100%",
												"outline-color": "#ffffff",
												"height":"40px",
												"paddingLeft":"5PX"

											}}
	>
	</input>

	</div>

	</div>

	</div>

			
	<div className="div-2"
	>
			
	<div className="div-2"
	>
			
	<label className="label-2" for="input-3"
	>
			Password
	</label>

			
	<div className="input"
	style={{
		"padding": "0px",
		"height": "40px",
	"border-radius": "0px",
		"border": "1px solid #00000075",
	

	}}
	>
			
	<input id="input-3" placeholder="••••••••" type="password" className="label"
                                            style={{
												"width": "100%",
												"outline-color": "#ffffff",
												"paddingLeft":"5PX"
											}}
                                            
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
	>
	</input>

	</div>

	</div>

	</div>

	</div>

			
	<div className="row"
	>
			
	{/* <div className="checkbox"
	>
			
	<div className="checkbox-base-wrapper"
	>
			
	<div className="checkbox-base"
	>
	</div>

	</div>

			
	<div className="text-and-supporting"
	>
			
	<div className="text-3"
	>
			Remember for 30 days
	</div>

	</div>

	</div> */}

			
	{/* <button className="button"
	>
			
	<div className="text-4"
	>
			Forgot password
	</div>

	</button> */}

	</div>

			
	<div className="actions">
                                <button className="button-2" onClick={handleLogin}style={{"cursor":"pointer"}}>
                                    <div className="text-5">Sign in</div>
                                </button>
								{loading && (
<div className="loader-overlay">
    
        <img className="imageloader" src={loaderImage} alt="Loader" />
    
</div>
)}
                            </div>
	</div>

	</div>

			
	<div className="row-2"
	>
			
	<div className="text-6"
	>
			Don’t have an account?
	</div>

			
	{/* <button className="button-3"
	>
			
	<div className="text-4"
	>
			Sign up
	</div>

	</button> */}
	  <button className="button-3" onClick={handleSignUp} style={{"cursor":"pointer"}}>
                <div className="text-4">Sign up</div>
            </button>

	</div>

	</div>

	</div>

	</div>

			
	<div className="content-wrapper"
		
	>
			
	<img className="Image" id="i3zr3" src="assets/images/login-img.jpg"
	>
	</img>

			
	<div className="name-and-text-wrapper" style={{"margin-top":"-77px","color":"white"}}
	>
			
	<div className="div"
	>
			
	<div className="supporting-text-and"
	>
			
	<div className="text-and-supporting-2"
	>
			
	<p className="p"
	style={{fontSize:"16px",fontFamily:"system-ui"}}
	>
			“Success in creating AI would be the biggest event in human history.”
	</p>

	</div>

			
	<div className="arrows"
	>
			
	{/* <div className="testiomonial"
	>
			
	<img className="img" src="img/arrow-left.svg"
	>
	</img>

	</div> */}

			
	{/* <div className="testiomonial"
	>
			
	<img className="img" src="img/arrow-right.svg"
	>
	</img>

	</div> */}

	</div>

	</div>

	</div>

	</div>

	</div>

	</div>

			
		</ErrorBoundary>
	);
}

const mapStateToProps = function(state){
    return state.reducer
}

export default connect(mapStateToProps, null) ( Container );