import React, { Children, cloneElement, Fragment, isValidElement, PureComponent, useEffect, useRef, useState, useCallback } from "react";
import ReactDOM from 'react-dom';
import { connect } from "react-redux";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import { get } from 'lodash';
import _ from 'lodash';
import { Area, Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { 
	Chart, PieChart, RichTextEditor, RichText, 
	ToggleWrapper, Tabs, Slider, Form, MountHandler,
	Draggable, ErrorBoundary, toISOString, 
	toLocalString, ZoomablePannable, Progress, RangeSlider,
	getFileUrl, Select, MapBox, Marker, DynamicComponent
} from "../components/dittofi-components";

function Container(props) {
	let {
		dispatch,
	} = props;

	const [tasks, setTasks] = useState({});

	let history = useHistory();
	const handleLogout = () => {
		// Clear session storage
		sessionStorage.removeItem("username");
		sessionStorage.removeItem("messages");
		
		// Redirect the user to the login page or perform any other logout actions
		history.push("/login");
	  };

	var startPoll = (action) => {
	    console.log(`Starting poll for action ${action.name}`);

	    // Prevent polling the same action more than once.
	    if(tasks[action.name]) {
	        console.warn(`You're attempting to poll ${action.name} but it is already being polled.`);
	        return;
	    }
	    
	    // Handle stopping poll.
	    let canceled = false;
	    const cancel = () => canceled = true;

	    // Poll action.
	    const poll = () => {
	        console.log(`Polling action ${action.name}`);

	        if(canceled) {
	            return;
	        }

	        dispatch({ 
		        type: action.name, 
		        history: history, 
		        inputVariables: action.payload ? action.payload : {}
	        });
	        
	        setTimeout(() => poll(), action.pollingPeriod);
	    }

	    setTasks({...tasks, [action.name]: cancel});
	    poll();
	}

	var stopPoll = (actionName) => {	    
	    console.log(`Stopping poll for action ${actionName}`);

	    // Check task exists.
	    if(!tasks[actionName]) {
	        console.warn(`You're attempting to stop polling ${actionName} but this action is not running.`);
	        return;
	    }

	    tasks[actionName]();
	}

	var runAction = (action) => {
		if(action.pollingPeriod && action.pollingPeriod > 0) {
			startPoll(action);
		} else {
			dispatch({
				inputVariables: action.payload ? action.payload : {},
				params: { 
				},
				history: history,
				type: action.name,
				timeout: action.timeout && action.timeout > 0 ? action.timeout : null,
			});
		}
	}

	useEffect(() => {
		return () => {
			Object.keys(tasks).forEach((t) => stopPoll(t));
		}
	}, [tasks]);

	useEffect(() => {
		window.scrollTo(0, 0);
		/* TODO - run sagas on page mount (be sure not to rerun in page parameter change hook) */
	}, []);

	return (
		<ErrorBoundary>
			
				
	<div className="df-vh-100 d-flex df-d-flex df-bg-light df-overflow-auto"
	>
			
			<div className="df-bg-primary df-d-flex df-flex-column df-nav-sidebar df-overflow-auto"
				>
<Link to="/info">
					<div
					>


							<div className="df-d-flex df-rounded df-w-25 df-p-4"
							>

								<img className="df-w-100 df-rounded" src="assets/images/Group.svg"
								>
								</img>

							</div>

						

					</div>
					</Link>


					<div className="df-flex-grow-1"
					>

						<ToggleWrapper className="d-dropdown df-w-100 d--open"
						>

							<div className="d-dropdown-list df-bg-transparent df-position-static" role="d-toggle-wrapper"
							>
							</div>

						</ToggleWrapper>


						


						<Link className="d-inline-block df-w-100 df-d-flex df-text-white df-text-decoration-none df-p-3 df-ps-5" target="_self" to="/chathistory" type="spa"
						>
							<img className=""
								style={{ "height": "24px", "width": "24px" }}
								src='assets/images/history.svg' alt="Logo" />


							<span className="df-ms-3"
							>
								Chat history
							</span>

						</Link>


						<Link className="d-inline-block df-w-100 df-d-flex df-text-white df-text-decoration-none df-p-3 df-ps-5" target="_self" to="/fileupload" type="spa"
						>

							{/* <i className="fas fa-lock"
							>
							
							</i> */}
							{/* <i className="fa-sharp fa-thin fa-user-hair-mullet"></i> */}
						
							
							<img   className="" 
							style={{"height":"24px","width":"24px"}}
							src='assets/images/database.svg' alt="Logo" />

							<span className="df-ms-3"
							>
								Train AI
							</span>

						</Link>


						<Link className="d-inline-block df-w-100 df-d-flex df-text-white df-text-decoration-none df-p-3 df-ps-5" target="_self" to="/info" type="spa"
						>

							<img className=""
								style={{ "height": "24px", "width": "24px" }}
								src='assets/images/chat.svg' alt="Logo" />


							<span className="df-ms-3"
							>
								Chat
							</span>

						</Link>

					</div>
					<div
					>

						<div
						>

							<a className="d-inline-block df-w-100 df-d-flex df-align-items-center df-text-decoration-none df-text-white df-p-3" href="#" onClick={() => handleLogout()} target="_self" type="external"
							>

<img   className="" 
							style={{"height":"24px","width":"24px"}}
							src='assets/images/logout.png' alt="Logo" />


								<span className="df-ms-3"
								>
									Logout
								</span>

							</a>

						</div>

					</div>

					<div
					>

						<div
						>

							<a className="d-inline-block df-w-100 df-d-flex df-align-items-center df-text-decoration-none df-text-white df-p-3" href="#/setting" target="_self" type="external"
							>

								<i className="fas fa-cog"
								>
								</i>


								<span className="df-ms-3"
								>
									Settings
								</span>

							</a>

						</div>

					</div>

				</div>

			
	<div className="df-flex-grow-1 d-flex df-d-flex df-flex-column"
	>
			
	<div className="df-flex-grow-1 df-overflow-auto df-p-4" id="io06b"
	>
			
	<div
	>
			
	<h4 className="Heading" id="i4f1ta"
	>
			Upload data to train AI
	</h4>

			
	<p className="Paragraph" id="i0wjow"
	>
			Empower your organisation's users with tailored insights. Upload your data to teach the AI, enabling it to deliver personalised answers relevant to your organisation's needs. while ensuring the highest standards of data safety and security.
	</p>

	</div>

			
	<div className="DivBlock" id="ishxai"
	>
			
	<label className="d-file-upload d-button FileUpload" id="ipfvf1"
	>
			
	<span id="i5mkxi"
	>
			Upload File   
	</span>

			
	<input className="d-file-upload-input" type="file"
	>
	</input>

	</label>

			
	<img className="Image_2" id="ikyw8" src="https://dittofipublicfiles.s3.us-west-2.amazonaws.com/7473/30128-upload-cloud-02.svg"
	>
	</img>

	</div>

			
	<div id="i1ezz"
	>
			
	<div className="df-bg-white df-rounded df-border df-overflow-hidden"
	>
			
	<div className="DivBlock_1" id="i5jl9"
	>
			
	<h5 className="Heading_1" id="i62ah"
	>
			Recent Uploads
	</h5>

			
	<div className="DivBlock_2" id="iho9f"
	>
			
	<div className="df-d-flex"
	>
			
	<div className="df-d-flex"
	>
			
	<input className="d-input d-m-0 df-m-0 df-bg-light df-rounded-start" placeholder="Search" type=""
	>
	</input>

			
	<button className="d-button fa df-bg-primary df-rounded-end fa-search"
	>
	</button>

	</div>

	</div>

	</div>

	</div>

			
	<div className="df-p-3"
	>
			
	<table className="d-table" id="i0n8ea"
	>
			
	<thead
	>
			
	<tr
	>
			
	<th id="icfdwe"
	>
			
	<span className="Span" id="ig5wl2"
	>
			File Name
	</span>

	</th>

			
	<th
	>
			
	<span className="Span_1" id="iy8ij8"
	>
			Size
	</span>

	</th>

			
	<th id="ivhnu"
	>
			
	<span className="Span_2" id="ioueov"
	>
			Date
	</span>

	</th>

	</tr>

	</thead>

			
	<tbody id="i9czig"
	>
			
	<tr id="ij78eu"
	>
			
	<td
	>
			
	<div className="df-d-flex df-align-items-center"
	>
			
	<div
	>
			
	<h4 className="df-mb-1"
	>
			Samuel Virgo
	</h4>

	</div>

	</div>

	</td>

			
	<td
	>
			
	<div className="df-d-flex df-align-items-center"
	>
			
	<div
	>
			
	<span className="Span_3" id="iiae8"
	>
			Dittofi
	</span>

	</div>

	</div>

	</td>

			
	<td id="ily0g"
	>
			
	<span className="Span_4" id="iwx99"
	>
			12/12/2021
	</span>

	</td>

			
	<td id="icwu6"
	>
			
	<a className="d-inline-block" href="#" id="idt1a" target="_self" type="external"
	>
			
	<img className="Image_4" id="ie28x" src="https://dittofipublicfiles.s3.us-west-2.amazonaws.com/7473/30129-trash-2.svg"
	>
	</img>

	</a>

	</td>

	</tr>

	</tbody>

	</table>

	</div>

	</div>

	</div>

	</div>

	</div>

	</div>

			
		</ErrorBoundary>
	);
}

const mapStateToProps = function(state){
    return state.reducer
}

export default connect(mapStateToProps, null) ( Container );