


// import React, { useState, useEffect } from 'react';
// import { connect } from 'react-redux';
// import { PieChart } from 'react-minimal-pie-chart';
// import './predictionstyles.css';
// import { Link, useLocation, useParams, useHistory } from "react-router-dom";


// function PersonPieChart({ data }) {
//   // Calculate percentages
//   const total = data.naturalness + data.coherence + data.understandability;
//   const naturalnessPercentage = (data.naturalness / total) * 100;
//   const coherencePercentage = (data.coherence / total) * 100;
//   const understandabilityPercentage = (data.understandability / total) * 100;
//   const overallPercentage = (data.overall / total) * 100;

//   // Define colors based on percentages
//   const colors = [
//     { title: 'Naturalness', value: naturalnessPercentage, color: '#E38627' },
//     { title: 'Coherence', value: coherencePercentage, color: '#C13C37' },
//     { title: 'Understandability', value: understandabilityPercentage, color: '#6A2135' },
//   ];

//   return (
//     <PieChart
//       data={colors}
//       style={{ fontWeight: 'bold', fontSize: '3px' }}
//       label={({ dataEntry }) => `${dataEntry.title}: ${dataEntry.value.toFixed(2)}%`}
//     />
//   );
// }

// function Container(props) {
//   const [jsonData, setJsonData] = useState(null);
//   const [selectedPerson, setSelectedPerson] = useState(null);
//   const [showTotal, setShowTotal] = useState(false);
  
  
// 	let history = useHistory();
//   const handleLogout = () => {
// 		// Clear session storage
// 		sessionStorage.removeItem("username");
// 		sessionStorage.removeItem("messages");
		
// 		// Redirect the user to the login page or perform any other logout actions
// 		history.push("/login");
// 	  };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch('data.json');
//         if (!response.ok) {
//           throw new Error('Failed to fetch data');
//         }
//         const data = await response.json();
//         setJsonData(data);
//       } catch (error) {
//         console.error('Error fetching JSON data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   const handlePersonClick = (person) => {
//     setSelectedPerson(person);
//     setShowTotal(false); // Reset to hide total pie chart
//   };

//   const handleTotalClick = () => {
//     setShowTotal(true);
//   };

//   if (!jsonData) {
//     return <div>Loading...</div>;
//   }

//   const { sneha } = jsonData;

//   return (
    
//     <div className="container2">
      
      
//       <div className="data-section">
//       <button style={{ 
//     border: '1px solid black',
//     backgroundColor: 'white',
//     borderRadius: '5px 5px 0 0',
//     color: 'black',
//     fontSize: '16px',
//     padding: '10px 20px',
//     cursor: 'pointer',
//     borderBottom: 'none',
//     marginBottom: '-1px'
// }} onClick={() => handlePersonClick('sneha')}>Sneha</button>


//         {selectedPerson === 'sneha' && (
//           <div>
//             {!showTotal && (
//               <button onClick={handleTotalClick}>Total</button>
//             )}
//             {!showTotal && (
//               <button onClick={() => setShowTotal(false)}>Breakdown</button>
//             )}
//             {showTotal && (
//               <PieChart
//                 data={[
//                   { title: 'Accuracy', value: sneha.Total, color: '#4CAF50' },
//                   { title: 'Inconsistence', value: 100 - sneha.Total, color: '#f44336' },
//                 ]}
//                 style={{ fontWeight: 'bold', fontSize: '2px' }}
//                 label={({ dataEntry }) => `${dataEntry.title}: ${dataEntry.value.toFixed(2)}%`}
//               />
//             )}
//             {!showTotal && (
//               <ul>
//               {sneha.BreakDown.map((item, index) => (
//                 <li key={index}>
//                   <div style={{display:"flex",gap:"2px"}}>
//                   <div className="squre" style={{
//                   height:"10px",
//                   width:"10px",
//                   background:"#E38627",
//                   marginTop:"6px",
                 
//                   }}> </div><p>Naturalness: {item.naturalness}</p>
//                   </div>
//                   <div style={{display:"flex",gap:"2px"}}>
//                   <div className="squre" style={{
//                   height:"10px",
//                   width:"10px",
//                   background:"#C13C37",
//                   marginTop:"6px",
                 
//                   }}> </div><p>Coherence: {item.coherence}</p>
//                   </div>
//                   <div style={{display:"flex",gap:"2px"}}>
//                   <div className="squre" style={{
//                   height:"10px",
//                   width:"10px",
//                   background:"#6A2135",
//                   marginTop:"6px",
                 
//                   }}> </div><p>Understandability: {item.understandability}</p>
//                   </div>
//                   <div style={{display:"flex",gap:"2px"}}>
//                   <div className="squre" style={{
//                   height:"10px",
//                   width:"10px",
//                   background:"#215761",
//                   marginTop:"6px",
                 
//                   }}> </div> <p>Overall: {item.overall}</p>
//                   </div>
            
                  
                  
                 
//                   <PersonPieChart data={item} />
//                 </li>
//               ))}
//             </ul>
//             )}
//           </div>
//         )}
//       </div>
     
     
//     </div>
//   );
// }

// const mapStateToProps = (state) => {
//   return state.reducer;
// };

// export default connect(mapStateToProps, null)(Container);


import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { PieChart } from 'react-minimal-pie-chart';
import './predictionstyles.css';
import { Link, useHistory } from 'react-router-dom';


function PersonPieChart({ data }) {
  // Calculate percentages
  const total = data.naturalness + data.coherence + data.understandability;
  const naturalnessPercentage = (data.naturalness / total) * 100;
  const coherencePercentage = (data.coherence / total) * 100;
  const understandabilityPercentage = (data.understandability / total) * 100;
  const overallPercentage = (data.overall / total) * 100;

  // Define colors based on percentages
  const colors = [
    { title: 'Naturalness', value: naturalnessPercentage, color: '#E38627' },
    { title: 'Coherence', value: coherencePercentage, color: '#C13C37' },
    { title: 'Understandability', value: understandabilityPercentage, color: '#6A2135' },
  ];

  return (
    <PieChart
      data={colors}
      style={{ fontWeight: 'bold', fontSize: '3px' }}
      label={({ dataEntry }) => `${dataEntry.title}: ${dataEntry.value.toFixed(2)}%`}
    />
  );
}

function Container(props) {
  const [username, setUsername] = useState("");
  const [jsonData, setJsonData] = useState(null);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [showTotal, setShowTotal] = useState(false);
  const history = useHistory();

  const handleLogout = () => {
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('messages');
    history.push('/login');
  };
  useEffect(() => {
		// Retrieve username from session storage
		const usernameFromSession = sessionStorage.getItem("username");
		

		if (usernameFromSession) {
			const userId = usernameFromSession.split("_")[0];
			setUsername(userId);
			// setUsername(usernameFromSession);
		} else {
			// Redirect or handle the case when the username is not available in session
			history.push("/login"); // Redirect to login page
		}
		
	}, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('data.json');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setJsonData(data);
      } catch (error) {
        console.error('Error fetching JSON data:', error);
      }
    };

    fetchData();
  }, []);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch('file:///C:/deployment/ai_virtual_assistant/output/data_bkp.json');
  //       console.log('Response status:', response.status);
  //       if (!response.ok) {
  //         throw new Error('Failed to fetch data');
  //       }
  //       const data = await response.json();
  //       setJsonData(data);
        
  //     } catch (error) {
  //       console.error('Error fetching JSON data:', error);
  //     }
  //   };
  
  //   fetchData();
  // }, []);
  
  



  
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch('data.json');
  //       if (!response.ok) {
  //         throw new Error('Failed to fetch data');
  //       }
  //       const data = await response.json();
  //       setJsonData(data);
  //     } catch (error) {
  //       console.error('Error fetching JSON data:', error);
  //     }
  //   };

  //   fetchData();

  //   // Schedule API call at 8:25 PM IST daily
  //   const now = new Date();
  //   const millisTill840 = new Date(
  //     now.getFullYear(),
  //     now.getMonth(),
  //     now.getDate(),
  //     5,
  //     20,
  //     0,
  //     0
  //   ) - now;
  //   // if (millisTill840 < 0) {
  //   //   millisTill840 += 86400000; // it's after 8:40 PM, try 8:40 PM tomorrow.
  //   // }
  //   setTimeout(function () {
  //     fetchPredictionAll(); // Call your API function here
  //   }, millisTill840);
  // }, []);


  // const fetchPredictionAll = async () => {
  //   try {
  //     const response = await fetch('http://localhost:8000/chat/submit/prediction_all', {
  //       method: 'POST', // Specify the HTTP method
  //       headers: {
  //         'Content-Type': 'application/json', // Specify the content type if sending JSON data
  //         // Add any other headers as needed
  //       },
  //       body: JSON.stringify({}) // If you have data to send in the body, stringify it here
  //     });
  
  //     if (!response.ok) {
  //       throw new Error('Failed to fetch prediction data');
  //     }
  
  //     const data = await response.json();
  //     console.log('Prediction data fetched:', data);
  //     // You can process the fetched data as needed
  //   } catch (error) {
  //     console.error('Error fetching prediction data:', error);
  //   }
  // };
  
  // const fetchPredictionAll = async () => {
  //   try {
  //     const response = await fetch('http://localhost:8000/chat/submit/prediction_all');
  //     if (!response.ok) {
  //       throw new Error('Failed to fetch prediction data');
  //     }
  //     const data = await response.json();
  //     console.log('Prediction data fetched:', data);
  //     // You can process the fetched data as needed
  //   } catch (error) {
  //     console.error('Error fetching prediction data:', error);
  //   }
  // };

  const handlePersonClick = (person) => {
    setSelectedPerson(person);
    setShowTotal(false);
  };

  if (!jsonData) {
    return <div>Loading...</div>;
  }
  const handleTotalClick = () => {
        setShowTotal(true);
      };

  return (
    <div className="container2">
    <div className="df-bg-primary df-d-flex df-flex-column df-nav-sidebar df-overflow-auto"
				style={{height:"100vh"}}>

					<div
					>


							<div className="df-d-flex df-rounded df-w-25 df-p-4"
							>

								<img className="df-w-100 df-rounded" src="assets/images/Group.svg"
								>
								</img>

							</div>

						

					</div>


					<div className="df-flex-grow-1"
					>

						


						


						<Link style={{ "padding-left": "1rem"}} className="d-inline-block df-w-100 df-d-flex df-text-white df-text-decoration-none df-p-3 df-ps-5" target="_self" to="/chathistory" type="spa"
						>
							<img className=""
								style={{ "height": "24px", "width": "24px" }}
								src='assets/images/history.svg' alt="Logo" />


							<span className="df-ms-3"
							>
								Chat History
							</span>

						</Link>
						{/* <Link style={{ "padding-left": "1rem"}} className="d-inline-block df-w-100 df-d-flex df-text-white df-text-decoration-none df-p-3 df-ps-5" target="_self" to="/prediction" type="spa"
						>
							<img className=""
								style={{ "height": "24px", "width": "24px" }}
								src='assets/images/history.svg' alt="Logo" />


							<span className="df-ms-3"
							>
							 Prediction
							</span>

						</Link> */}
						<Link style={{ "padding-left": "1rem"}} className="d-inline-block df-w-100 df-d-flex df-text-white df-text-decoration-none df-p-3 df-ps-5" target="_self" to="/allprediction" type="spa"
						>
							<img className=""
								style={{ "height": "24px", "width": "24px" }}
								src='assets/images/history.svg' alt="Logo" />


							<span className="df-ms-3"
							>
							ALL Prediction
							</span>

						</Link>

					


						<Link style={{ "padding-left": "1rem"}} className="d-inline-block df-w-100 df-d-flex df-text-white df-text-decoration-none df-p-3 df-ps-5" target="_self" to="/info" type="spa"
						>

							<img className=""
								style={{ "height": "24px", "width": "24px" }}
								src='assets/images/chat.svg' alt="Logo" />


							<span className="df-ms-3"
							>
								Chat
							</span>

						</Link>

					</div>

					<div
					>

						<div
						>

							<a className="d-inline-block df-w-100 df-d-flex df-align-items-center df-text-decoration-none df-text-white df-p-3" href="#"  onClick={() => handleLogout()} target="_self" type="external"
							>

<img   className="" 
							style={{"height":"24px","width":"24px"}}
							src='assets/images/logout.png' alt="Logo" />


								<span className="df-ms-3"
								>
									Logout
								</span>

							</a>

						</div>

					</div>
					<div
					>

						<div
						>

							<a className="d-inline-block df-w-100 df-d-flex df-align-items-center df-text-decoration-none df-text-white df-p-3" href="#/setting" target="_self" type="external"
							>

								<i className="fas fa-cog"
								>
								</i>


								<span className="df-ms-3"
								>
									Settings
								</span>

							</a>

						</div>

					</div>
          <div className="" style={{ "border-radius": "50%" }}>
										<img className="" style={{ "height": "40px", "width": "40px" }} src='assets/images/profile.png' alt="Logo" />
										<span >{username}</span>
									</div>

				</div>
      <div className="data-section" style={{display:"flex"}}>
        {Object.keys(jsonData).map((user) => (
          <div key={user} >
         
            <button          style={{ 
    border: '1px solid black',
    backgroundColor: 'white',
    borderRadius: '5px 5px 0 0',
    color: 'black',
    fontSize: '16px',
    padding: '10px 20px',
    cursor: 'pointer',
    borderBottom: 'none',
    marginBottom: '-1px'
}}
      onClick={() => handlePersonClick(user)}>{user}</button>
            {selectedPerson === user && (
              <div>
                {!showTotal && <button onClick={handleTotalClick}>Total</button>}
                {!showTotal && <button onClick={() => setShowTotal(false)}>Breakdown</button>}
                {showTotal && (
                  <PieChart
                    data={[
                      { title: 'Accuracy', value: jsonData[user].Total, color: '#4CAF50' },
                      { title: 'Inconsistence', value: 100 - jsonData[user].Total, color: '#f44336' },
                    ]}
                    style={{ fontWeight: 'bold', fontSize: '2px' }}
                    label={({ dataEntry }) => `${dataEntry.title}: ${dataEntry.value.toFixed(2)}%`}
                  />
                  
                )}
                {!showTotal && (
                  <ul style={{width:"350px"}}>
                    {jsonData[user].BreakDown.map((item, index) => (
                      <li key={index}>
                        {/* Your breakdown data */}
                        <div style={{display:"flex",gap:"2px"}}>
                  <div className="squre" style={{
                  height:"10px",
                  width:"10px",
                  background:"#E38627",
                  marginTop:"6px",
                 
                  }}> </div><p>Naturalness: {item.scores.naturalness}</p>
                  </div>
                  <div style={{display:"flex",gap:"2px"}}>
                  <div className="squre" style={{
                  height:"10px",
                  width:"10px",
                  background:"#C13C37",
                  marginTop:"6px",
                 
                  }}> </div><p>Coherence: {item.scores.coherence}</p>
                  </div>
                  <div style={{display:"flex",gap:"2px"}}>
                  <div className="squre" style={{
                  height:"10px",
                  width:"10px",
                  background:"#6A2135",
                  marginTop:"6px",
                 
                  }}> </div><p>Understandability: {item.scores.understandability}</p>
                  </div>
                  <div style={{display:"flex",gap:"2px"}}>
                  <div className="squre" style={{
                  height:"10px",
                  width:"10px",
                  background:"#215761",
                  marginTop:"6px",
                 
                  }}> </div> <p>Overall: {item.scores.overall}</p>
                  </div>
                  <div style={{display:"flex",gap:"2px"}}>
                  <div className="squre" style={{
                  height:"10px",
                  width:"10px",
                  marginTop:"6px",
                 
                  }}> </div> <p>Query: {item.query}</p>
                  </div>
                        <PersonPieChart data={item.scores} />
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return state.reducer;
};

export default connect(mapStateToProps, null)(Container);

