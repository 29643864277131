import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { Provider, connect } from 'react-redux';
import thunk from 'redux-thunk';
import * as effects from 'redux-saga/effects';
import { hashHistory } from 'react-router';
import { HashRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { api } from './api/rtkQuery.js';
import InfoPage from './pages/InfoPage';
import Prediction from './pages/Prediction.js';
import AllPrediction from './pages/AllPrediction.js';


import FileUploadPage from './pages/FileUploadPage';
// import ChatPage from './pages/ChatPage';
import HomePage from './pages/HomePage';
import LoginComponent from './pages/LoginComponent.js';
import ChatHistory from './pages/ChatHistory.js';
import Settings from './pages/Settings.js';

import reducer from './redux/reducer.js';
import mainSaga from './sagas/saga.js';
import { initEffects } from './sagas/saga.js';
import myMiddleware from './redux/middleware.js';

//import './index.css';

import * as Components from './components/dittofi-components.js';

Object.entries(Components).forEach(([componentName, component]) => {
	// Check if the component is a function (React component)
	if (typeof component === 'function') {
		Components.registerComponent(componentName, component);
	}
});

initEffects(effects);

const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducer, applyMiddleware(myMiddleware, sagaMiddleware, thunk, api.middleware));
sagaMiddleware.run(mainSaga);

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {error: ""};
	}

	componentDidCatch(error, errorInfo) {
		this.setState({error: `${error.name}: ${error.message}`});
	}

	render() {
		const {error} = this.state;
		if (error) {
			return (
				<div style={{color:"red"}}>{error}</div>
			);
		} else {
			return this.props.children;
		}
	}
}

class _App extends React.Component {
	componentDidMount() {
		const { dispatch } = this.props;
		dispatch({ type: "on_app_started" });
		
		// const hasAPICallBeenMadeToday = localStorage.getItem('hasAPICallBeenMadeToday');
		// if (!hasAPICallBeenMadeToday) {
		//   const nowUTC = new Date();
		//   const nowIST = new Date(nowUTC.getTime() + 5.5 * 60 * 60 * 1000); // Convert UTC to IST (UTC+5:30)
		//   const targetTimeIST = new Date(nowIST);
		//   targetTimeIST.setHours(18); // 5 PM IST
		//   targetTimeIST.setMinutes(35); // 55 minutes
	
		//   const timeDifference = targetTimeIST.getTime() - nowIST.getTime();
	
		//   setTimeout(() => {
		// 	fetch('http://localhost:8000/chat/submit/prediction_all', {
		// 	  method: 'POST', // adjust this as per your API endpoint
		// 	  headers: {
		// 		'Content-Type': 'application/json',
		// 		// add any additional headers if required
		// 	  },
		// 	  body: JSON.stringify({}),
		// 	})
		// 	  .then(response => response.json())
		// 	  .then(data => console.log(data)) // handle the API response here
		// 	  .catch(error => console.error('Error:', error));
	
		// 	localStorage.setItem('hasAPICallBeenMadeToday', 'true');
		//   }, timeDifference);
		// }
	  }
	
	
	render() {
		let {
			state
		} = this.props;

		if(!state.reducer._app_initialized) {
			return (
				<div></div>
			);
		}

		return (
			<div style={{height:"100%", width:"100%"}}>
				<Router>
					<Switch>
							
							<Route  exact  path="/info">
								<InfoPage/>
							</Route>
							
							<Route  exact  path="/chathistory">
								<ChatHistory/>
							</Route>
							
							<Route  exact  path="/fileupload">
								<FileUploadPage/>
							</Route>
							
							{/* <Route  exact  path="/chat">
								<ChatPage/>
							</Route> */}
							
							<Route  exact  path="/">
								<HomePage/>
							</Route>
							<Route  exact  path="/login">
								<LoginComponent/>
							</Route>
							<Route  exact  path="/setting">
								<Settings/>
							</Route>
							<Route  exact  path="/prediction">
								<Prediction/>
							</Route>
							<Route  exact  path="/allprediction">
								<AllPrediction/>
							</Route>
					</Switch>
				</Router>
			</div>
		);
	}
}

const mapStateToProps = function(state){
		return {
			state: state
		}
}

const App = connect(mapStateToProps, null)( _App );

ReactDOM.render(
	<Provider store={store}>
		<ErrorBoundary>
			<App />
		</ErrorBoundary>
	</Provider>, document.getElementById('root')
);
