import React, { Children, cloneElement, Fragment, isValidElement, PureComponent, useEffect, useRef, useState, useCallback } from "react";
import ReactDOM from 'react-dom';
import { connect } from "react-redux";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import { get } from 'lodash';
import _ from 'lodash';
import loaderImage from "../assets/assets/images/loader1.gif";
import { Area, Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { 
	Chart, PieChart, RichTextEditor, RichText, 
	ToggleWrapper, Tabs, Slider, Form, MountHandler,
	Draggable, ErrorBoundary, toISOString, 
	toLocalString, ZoomablePannable, Progress, RangeSlider,
	getFileUrl, Select, MapBox, Marker, DynamicComponent
} from "../components/dittofi-components";

function Container(props) {
	let {
		dispatch,
	} = props;
	const apiUrl = process.env.REACT_APP_API_URL;

	const [tasks, setTasks] = useState({});
	const [username, setUsername] = useState("");
	const username2 = sessionStorage.getItem("username");
	const [historyData, setHistoryData] = useState([]);
	const [loading, setLoading] = useState(false)
	

	let history = useHistory();
	useEffect(() => {
		// Retrieve username from session storage
		const usernameFromSession = sessionStorage.getItem("username");
		const messagesFromSession = sessionStorage.getItem("messages");
	
		if (usernameFromSession) {
			const userId = usernameFromSession.split("_")[0];
            setUsername(userId);
			// setUsername(usernameFromSession);
		} else {
			// Redirect or handle the case when the username is not available in session
			history.push("/login"); // Redirect to login page
		}
		
	}, []);

	var startPoll = (action) => {
	    console.log(`Starting poll for action ${action.name}`);

	    // Prevent polling the same action more than once.
	    if(tasks[action.name]) {
	        console.warn(`You're attempting to poll ${action.name} but it is already being polled.`);
	        return;
	    }
	    
	    // Handle stopping poll.
	    let canceled = false;
	    const cancel = () => canceled = true;

	    // Poll action.
	    const poll = () => {
	        console.log(`Polling action ${action.name}`);

	        if(canceled) {
	            return;
	        }

	        dispatch({ 
		        type: action.name, 
		        history: history, 
		        inputVariables: action.payload ? action.payload : {}
	        });
	        
	        setTimeout(() => poll(), action.pollingPeriod);
	    }

	    setTasks({...tasks, [action.name]: cancel});
	    poll();
	}

	var stopPoll = (actionName) => {	    
	    console.log(`Stopping poll for action ${actionName}`);

	    // Check task exists.
	    if(!tasks[actionName]) {
	        console.warn(`You're attempting to stop polling ${actionName} but this action is not running.`);
	        return;
	    }

	    tasks[actionName]();
	}

	var runAction = (action) => {
		if(action.pollingPeriod && action.pollingPeriod > 0) {
			startPoll(action);
		} else {
			dispatch({
				inputVariables: action.payload ? action.payload : {},
				params: { 
				},
				history: history,
				type: action.name,
				timeout: action.timeout && action.timeout > 0 ? action.timeout : null,
			});
		}
	}

	useEffect(() => {
		return () => {
			Object.keys(tasks).forEach((t) => stopPoll(t));
		}
	}, [tasks]);

	useEffect(() => {
		window.scrollTo(0, 0);
		/* TODO - run sagas on page mount (be sure not to rerun in page parameter change hook) */
	}, []);
	
	const fetchHistoryData = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiUrl}/api/v1/chat/submit/${username2}/streaming/recall`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				}
			});
			const data = await response.json();
			if (Array.isArray(data)) {
				setLoading(false);
				setHistoryData(data);
				console.log(data)
			} else {
				console.error("Fetched data is not an array:", data);
			}
		} catch (error) {
			setLoading(false);
			console.error("Error fetching history data:", error);
		}
	};
	const handleLogout = () => {
		// Clear session storage
		sessionStorage.removeItem("username");
		sessionStorage.removeItem("messages");
		
		// Redirect the user to the login page or perform any other logout actions
		history.push("/login");
	  };

	useEffect(() => {
        fetchHistoryData();
    }, []); 
	
	const renderHumanContent = () => {
        return historyData
            .filter(item => item.type === "human")
            .map((item, index) => (
                <div key={index} className="df-p-3 df-text-white" style={{
					
					"width":"100%",
					"padding":"10px",
					"height":"35px",
					"color":"black"
				}}>
                    {item.content}
                </div>
            ));
    };
	const renderHumanContent3 = () => {
        return historyData
            .filter(item => item.type === "human")
            .map((item, index) => (
                <div key={index} className="df-p-3 df-text-white" style={{
					"background-color":"black",
					"border-radius": "10px",
					"border-color":"white",
					
					"width":"100%",
					"margin": "16px 5px 16px 5px",
					"padding":"10px",
					"height":"60px",
				
				}}>
                    {item.content}
                </div>
            ));
    };
	const renderHumanContent2 = () => {
        return historyData
            .filter(item => item.type === "ai")
            .map((item, index) => (
                <div key={index} className="df-p-3 df-text-white" style={{
					
					"border-radius": "5px",
					
					"padding":"25px",
					
					"width":"100%",
				
					"padding":"5px",
					"color":"black"
					
				}}>
                    {item.content}
                </div>
            ));
    };
	
	// const getCookie = (name) => {
	// 	const value = `; ${document.cookie}`;
	// 	const parts = value.split(`; ${name}=`);
	// 	if (parts.length === 2) return parts.pop().split(";").shift();
	// };

	return (
		<ErrorBoundary>
			
				
	<div className="df-vh-100 d-flex df-d-flex df-bg-light df-overflow-auto"
	style={{"font-family": "system-ui", " font-size": "16px"}}
	>
			{loading && (
<div className="loader-overlay">
    
        <img className="imageloader" src={loaderImage} alt="Loader" />
    
</div>
)}
<div className="df-bg-primary df-d-flex df-flex-column df-nav-sidebar df-overflow-auto" style={{"flex":"0 0 50px"}}
				>
<Link to="/info">
					<div
					>


							<div className="df-d-flex df-rounded df-w-25 df-p-4" style={{"padding":"10px"}}
							>

<img className="df-w-100 df-rounded" src="assets/images/R.svg"
								>
								</img>

								

							</div>

						

					</div>
					</Link>


					<div className="df-flex-grow-1"
					>

						<ToggleWrapper className="d-dropdown df-w-100 d--open"
						>

							<div className="d-dropdown-list df-bg-transparent df-position-static" role="d-toggle-wrapper"
							>
							</div>

						</ToggleWrapper>


						


						<Link className="d-inline-block df-w-100 df-d-flex df-text-white df-text-decoration-none df-p-3 df-ps-5"  style={{"padding-left":"1rem"}}target="_self" to="/chathistory" title="chathistory" type="spa"
						>
							<img className=""
								style={{ "height": "24px", "width": "24px" }}
								src='assets/images/history.svg' alt="Logo" />


							{/* <span className="df-ms-3"
							>
								Chat history
							</span> */}

						</Link>


						{/* <Link className="d-inline-block df-w-100 df-d-flex df-text-white df-text-decoration-none df-p-3 df-ps-5"  style={{"padding-left":"1rem"}} target="_self" to="/fileupload" type="spa"
						>

						
							
							<img   className="" 
							style={{"height":"24px","width":"24px"}}
							src='assets/images/database.svg' alt="Logo" />


						</Link> */}


						<Link className="d-inline-block df-w-100 df-d-flex df-text-white df-text-decoration-none df-p-3 df-ps-5"  style={{"padding-left":"1rem"}} target="_self" to="/info" type="spa"
						>

							<img className=""
								style={{ "height": "24px", "width": "24px" }}
								src='assets/images/chat.svg' alt="Logo" />


							{/* <span className="df-ms-3"
							>
								Chat
							</span> */}

						</Link>

					</div>

					<div
					>

						<div
						>

							<a className="d-inline-block df-w-100 df-d-flex df-align-items-center df-text-decoration-none df-text-white df-p-3" href="#" onClick={() => handleLogout()} target="_self" type="external"
							>

<img   className="" 
							style={{"height":"24px","width":"24px"}}
							src='assets/images/logout.png' alt="Logo" />


								{/* <span className="df-ms-3"
								>
									Logout
								</span> */}

							</a>

						</div>
					

					</div>
					<div
					>

						<div
						>

							<a className="d-inline-block df-w-100 df-d-flex df-align-items-center df-text-decoration-none df-text-white df-p-3" href="#/setting" target="_self" type="external"
							>

								<i className="fas fa-cog"
								>
								</i>


								{/* <span className="df-ms-3"
								>
									Settings
								</span> */}

							</a>

						</div>

					</div>
					<div className="" style={{ "border-radius": "50%" }}>
										<img className="" style={{ "height": "25px", "width": "25px" ,"border-radius": "50%" ,marginLeft:"10px"}} src='assets/images/profile.jpg' alt="Logo" />
										{/* <span >{username}</span> */}
									</div>

				</div>
			
	<div className="df-bg-primary df-d-flex df-flex-column df-nav-sidebar df-overflow-auto"
	style={{"flex":"0 0 290px"}}
	>
			
	<div
	>
			
	<a className="d-inline-block df-w-100 df-d-flex df-align-items-center df-text-white df-text-decoration-none" href="#" target="_self" type="external"
	>
			
	{/* <span className="df-fs-5"
	>
			R A G
	</span> */}
		{/* <div className="df-d-flex df-rounded df-w-25 df-p-4"
							styele={{"padding-left":"10px"}}
							>

								<img className="df-w-100 df-rounded" src="assets/images/Group.svg"
								>
								</img>

							</div> */}

	</a>

	</div>

			
	<div className="df-flex-grow-1"
	style={{"width":"265px"}}
	>
			
	<div
	>
		<span style={{"color":"white","marginLeft":"10px"}}>Your Chat History</span>
		 {/* <div>
            <button onClick={fetchHistoryData}>History</button>
        
        </div> */}
		<div 
	>
{renderHumanContent3()}

		</div>

		
			
	{/* <h5 className="df-p-3 df-text-white"
	>
			Tell me the Accountant Mobile Number
	</h5> */}

	</div>

	

			


			
	

	</div>

			
	<div
	>
			
	<div
	>
			
	{/* <a className="d-inline-block df-w-100 df-d-flex df-align-items-center df-text-decoration-none df-text-white df-p-3" href="#" target="_self" type="external"
	>
			
	<i className="fas fa-cog"
	>
	</i>

			
	<span className="df-ms-3"
	>
			Settings
	</span>

	</a> */}

	</div>

	</div>

	</div>

			
	<div className="df-flex-grow-1 d-flex df-d-flex df-flex-column"
	>
			
	<div className="df-flex-grow-1 df-overflow-auto df-p-4"
	>
			
	<div className="df-flex-grow-1 df-rounded df-bg-white df-border df-d-flex df-flex-column df-overflow-hidden"
	>
			
	
	<div className="df-flex-grow-1 df-p-3 df-overflow-auto">
    {renderHumanContent().map((content, index) => (
        <Fragment key={index}>
            <div className="df-d-flex df-align-items-start df-mb-3">
                <div className="" style={{
					"border-radius": "50%",
					"margin-top":"15px"
					
				}}>
					   <img className=""
								style={{ "height": "40px", "width": "40px" }}
								src='assets/images/profile.jpg' alt="Logo" />
                    <span style={{margin:"0px 5px 0px 5px"}}>{username}</span>
                </div>
                <div className="df-p-3 df-rounded df-bg-warning df-flex-grow-1">
                    {content}
                </div>
            </div>
            {renderHumanContent2()[index] && (
                <div className="df-d-flex df-align-items-start df-flex-row-reverse df-mb-3">
                    <div className="df-p-2 df-rounded df-bg-primary df-text-white df-ms-2"
					style={{
						"border-radius": "50%",
						"background-color": "#044856",
						"color": "black"
					}}
					
					>
                    <img className=""
								style={{ "height": "24px", "width": "24px" }}
								src='assets/images/rag.svg' alt="Logo" />
								 
                    </div>
					<span style={{ verticalAlign: "bottom", top: "7px", position: "relative", left: "4px" }}>RAG</span>
                    <div className="df-p-3 df-rounded df-flex-grow-1 df-bg-danger df-text-white"  style={{padding:"0px"}}>
                        {renderHumanContent2()[index]}
                    </div>
				
                </div>
				
            )}
        </Fragment>
    ))}
</div>





			
	{/* <div className="df-d-flex df-p-3 df-align-items-center"
	>
			
	<input className="d-input df-m-0 df-me-2 df-rounded" placeholder="Tell me the Accountant Mobile Number" type=""
	>
	</input>

			
	<button className="d-button df-bg-primary df-rounded"
	>
			Send
	</button>

	</div> */}

	</div>

	</div>

	</div>

	</div>

			
		</ErrorBoundary>
	);
}

const mapStateToProps = function(state){
    return state.reducer
}

export default connect(mapStateToProps, null) ( Container );