import * as actions from './actions.js';
import _ from 'lodash';
import { combineReducers } from 'redux';
import { api } from '../api/rtkQuery';

const initialState = {
    _app_initialized: false,
};

// Define MathOperator constants
const MathAdd = "add";
const MathSubtract = "subtract";
const MathMultiply = "multiply";
const MathDivide = "divide";
const MathModulus = "modulus";

// Helper function to perform math operations
const performMathOperation = (operator, value1, value2) => {
    switch (operator) {
        case MathAdd:
            return value1 + value2;
        case MathSubtract:
            return value1 - value2;
        case MathMultiply:
            return value1 * value2;
        case MathDivide:
            return value1 / value2;
        case MathModulus:
            return value1 % value2;
        default:
            return 0;
    }
};

const reducerMain = (state = initialState, action = {}) => {
    const {type, payload} = action;
    if(type === actions.remove_field) {
        const newState = _.cloneDeep(state);
        const deleted = _.unset(newState, payload.key);
        if(!deleted) {
            console.warn(`Could not remove field at ${payload.key}`);
        }

        return newState;
    } else if (type === actions.change_input) {
        return _.setWith(_.clone(state), payload.name, payload.value, _.clone)
    } else if (type === actions.perform_math_operation) {
        const { name, operator, variable, value } = payload;
        return _.setWith(_.clone(state), name, performMathOperation(operator, variable, value), _.clone)
    }
   
    return state;
}

const reducer = combineReducers({
    reducer: reducerMain,
    [api.reducerPath]: api.reducer
});

export default reducer;