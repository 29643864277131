import React, { Children, cloneElement, Fragment, isValidElement, PureComponent, useEffect, useRef, useState, useCallback } from "react";
import ReactDOM from 'react-dom';
import { connect } from "react-redux";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import { get } from 'lodash';
import _ from 'lodash';
import { Area, Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import loaderImage from "../assets/assets/images/loader1.gif";
import { ToastContainer, toast } from 'react-toastify'; // Import the entire module
import 'react-toastify/dist/ReactToastify.css';
import {
	Chart, PieChart, RichTextEditor, RichText,
	ToggleWrapper, Tabs, Slider, Form, MountHandler,
	Draggable, ErrorBoundary, toISOString,
	toLocalString, ZoomablePannable, Progress, RangeSlider,
	getFileUrl, Select, MapBox, Marker, DynamicComponent
} from "../components/dittofi-components";

function Container(props) {
	let {
		dispatch,
	} = props;
	const apiUrl = process.env.REACT_APP_API_URL;

	const [tasks, setTasks] = useState({});

	let history = useHistory();
	const [username, setUsername] = useState("");
	const username2 = sessionStorage.getItem("username");
	const [message, setMessage] = useState("");
	const [sentMessage, setSentMessage] = useState("");
	const [responseText, setResponseText] = useState("");
	const [messageSent, setMessageSent] = useState(false);
	const [loading, setLoading] = useState(false);
	const [messages, setMessages] = useState([]); // Array to store messages and responses
	const messagesEndRef = useRef(null);
	const [isModalOpen, setIsModalOpen] = useState(false); 
	const [copiedData, setCopiedData] = useState('');
const [thumbsDownValue, setThumbsDownValue] = useState(0);
const [thumbsUpValue, setThumbsUpValue] = useState(0);
const [commentValue, setCommentValue] = useState('');
const [showSuggestions, setShowSuggestions] = useState(false);
const [suggestions, setSuggestions] = useState([]);
const [selectedSuggestion, setSelectedSuggestion] = useState('');
const [positiveSuggestions, setPositiveSuggestions] = useState(true);



const handleThumbsUpClick = () => {
	setThumbsUpValue(1); 
	setIsModalOpen(true); // Open the modal when thumbs-down is clicked
	setShowSuggestions(true);
	setPositiveSuggestions(true); 
  };
	
	const handleThumbsDownClick = () => {
		setThumbsDownValue(-1);
		setIsModalOpen(true); // Open the modal when thumbs-down is clicked
		setShowSuggestions(true);
		setPositiveSuggestions(false); 
	  };
	const handleThumbsDown = () => {
		
		handleThumbsDownClick(); // Open modal if needed
	};
	const handleThumbsUp=()=>{
		
		handleThumbsUpClick(); 
		

	}
	
	
	  const closeModal = () => {
		setIsModalOpen(false); // Close the modal
		setShowSuggestions(false);
	  };
	  const handleSendComment = () => {
		console.log('Comment sent:', commentValue);
		setCommentValue('');
		sendFeedback(); // Send feedback data after commenting
	};
	const handleCommentChange = (event) => {
		setCommentValue(event.target.value);
	};
	const handleSelectSuggestion = (suggestion) => {
		setSelectedSuggestion(suggestion); 
        // setShowSuggestions(false); 
		// Close the suggestions modal
    };



	const handleLogout = () => {
		// Clear session storage
		sessionStorage.removeItem("username");
		sessionStorage.removeItem("messages");

		// Redirect the user to the login page or perform any other logout actions
		history.push("/login");
	};
	const handleChange = (event) => {
		setMessage(event.target.value);
	};

	var startPoll = (action) => {
		console.log(`Starting poll for action ${action.name}`);

		// Prevent polling the same action more than once.
		if (tasks[action.name]) {
			console.warn(`You're attempting to poll ${action.name} but it is already being polled.`);
			return;
		}

		// Handle stopping poll.
		let canceled = false;
		const cancel = () => canceled = true;

		// Poll action.
		const poll = () => {
			console.log(`Polling action ${action.name}`);

			if (canceled) {
				return;
			}

			dispatch({
				type: action.name,
				history: history,
				inputVariables: action.payload ? action.payload : {}
			});

			setTimeout(() => poll(), action.pollingPeriod);
		}

		setTasks({ ...tasks, [action.name]: cancel });
		poll();
	}

	var stopPoll = (actionName) => {
		console.log(`Stopping poll for action ${actionName}`);

		// Check task exists.
		if (!tasks[actionName]) {
			console.warn(`You're attempting to stop polling ${actionName} but this action is not running.`);
			return;
		}

		tasks[actionName]();
	}

	var runAction = (action) => {
		if (action.pollingPeriod && action.pollingPeriod > 0) {
			startPoll(action);
		} else {
			dispatch({
				inputVariables: action.payload ? action.payload : {},
				params: {
				},
				history: history,
				type: action.name,
				timeout: action.timeout && action.timeout > 0 ? action.timeout : null,
			});
		}
	}

	useEffect(() => {
		return () => {
			Object.keys(tasks).forEach((t) => stopPoll(t));
		}
	}, [tasks]);
	useEffect(() => {
		// Retrieve username from session storage
		const usernameFromSession = sessionStorage.getItem("username");
		const messagesFromSession = sessionStorage.getItem("messages");

		if (usernameFromSession) {
			const userId = usernameFromSession.split("_")[0];
			setUsername(userId);
			// setUsername(usernameFromSession);
		} else {
			// Redirect or handle the case when the username is not available in session
			history.push("/login"); // Redirect to login page
		}
		if (messagesFromSession) {
			setMessages(JSON.parse(messagesFromSession));
		}
	}, [history]);

	useEffect(() => {
		window.scrollTo(0, 0);
		/* TODO - run sagas on page mount (be sure not to rerun in page parameter change hook) */
	}, []);
	

	const handleSendMessage = () => {
		setLoading(true); // Set loading state to true to indicate that the message is being sent
		if (!message.trim()) {
			return; // If the message is empty or contains only whitespace, do nothing
		}
		const url = `${apiUrl}/api/v1/chat/submit/${username2}/streaming/retrieve?request=${encodeURIComponent(message)}`;
		// Construct the URL for the API endpoint
		fetch(url, {
			method: 'POST', // Make a POST request
			headers: {
				'Content-Type': 'application/json' // Specify content type as JSON
			}
		})
			.then(response => {
				if (response.ok) {
					return response.json(); // If response status is OK, parse response JSON

				} else {
					throw new Error('Network response was not ok'); // If response status is not OK, throw an error
				}
			})
			.then(data => {
				setResponseText(data); // Store response data in component state
				console.log(data)
				setLoading(false); // Set loading state to false, indicating that the message has been sent
				setMessageSent(true); // Set message sent state to true
				setSentMessage(message); // Store the sent message
				setMessage(''); // Reset the input field
				setMessages([...messages, { sentMessage: message, responseText: data }]);
				sessionStorage.setItem("messages", JSON.stringify([...messages, { sentMessage: message, responseText: data }]));


			})
			.catch(error => {
				console.error('Error sending message:', error.message); // Log the error message
				setLoading(false); // Set loading state to false
				// Handle error appropriately
			});
		scrollToBottom();
	};
	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
	};
	useEffect(() => {
		scrollToBottom(); // Initially scroll to the bottom when component mounts
	}, []);

	useEffect(() => {
		// Whenever messages state changes, scroll to the bottom
		scrollToBottom();
	}, [messages]);
	const handleCopy = (answer) => {
		setCopiedData(answer);
		navigator.clipboard.writeText(answer)
			.then(() => {
				console.log('Answer copied to clipboard');
			})
			.catch(err => {
				console.error('Failed to copy answer: ', err);
			});
	};
	const sendFeedback = async () => {
		const url = `${apiUrl}/api/v1/chat/submit/${username2}/streaming/feedback`;
	  
		try {
		  let ratingValue = 0; // Initialize the rating value
	  
		  if (thumbsUpValue === 1) {
			ratingValue = 1; // Set ratingValue to 1 if thumbsUpValue is 1 (thumbs-up clicked)
		  } else if (thumbsDownValue === -1) {
			ratingValue = -1; // Set ratingValue to -1 if thumbsDownValue is -1 (thumbs-down clicked)
		  }
	  
		  const response = await fetch(url, {
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json'
			},
			body: JSON.stringify({
			  rating: ratingValue, // Use the determined rating value
			  feedback: commentValue,
			  select_comment: selectedSuggestion
			})
		  });
	  
		  if (!response.ok) {
			throw new Error('Network response was not ok');
			toast.error('Failed to send your feedback');
		  }
	  
		  const data = await response.json();
		  console.log('Feedback sent:', data);
		  // Reset state after sending feedback
		  setCopiedData('');
		  setThumbsDownValue(0); // Reset thumbsDownValue
		  setThumbsUpValue(0); // Reset thumbsUpValue
		  setCommentValue('');
		  closeModal(); // Close the modal if needed
		  toast.success('Thanks for your feedback');
		} catch (error) {
		  console.error('Error sending feedback:', error);
		  // Handle error appropriately
		}
	  };
	  

	// const sendFeedback = async () => {
	// 	const url = `${apiUrl}/api/v1/chat/submit/${username2}/streaming/feedback`;
	
	// 	try {
	// 		const response = await fetch(url, {
	// 			method: 'POST',
	// 			headers: {
	// 				'Content-Type': 'application/json'
	// 			},
	// 			body: JSON.stringify({
	// 				// rating: isModalOpen && thumbsUpValue > 0 ? thumbsUpValue : thumbsDownValue,
	// 				// rating: thumbsDownValue,
					
	// 				rating : thumbsUpValue > 0 ? thumbsUpValue : thumbsDownValue * -1,
			
	// 			feedback: commentValue,
	// 			select_comment: selectedSuggestion 
	// 			})
	// 		});
	
	// 		if (!response.ok) {
	// 			throw new Error('Network response was not ok');
	// 			toast.error('failed to send your feed back');
	// 		}
	
	// 		const data = await response.json();
	// 		console.log('Feedback sent:', data);
	// 		// Reset state after sending feedback
	// 		setCopiedData('');
	// 		setThumbsDownValue(-1);
	// 		setCommentValue('');
	// 		closeModal(); // Close the modal if needed
	// 		toast.success('Thanks for your feed back');
	// 	} catch (error) {
	// 		console.error('Error sending feedback:', error);
	// 		// Handle error appropriately
	// 	}
	// };
	


	return (
		<ErrorBoundary>

<ToastContainer />
			<div className="df-vh-100 d-flex df-d-flex df-bg-light df-overflow-auto"
				style={{ "font-family": "system-ui", " font-size": "16px" }}
			>

				{loading && (
					<div className="loader-overlay">

						<img className="imageloader" src={loaderImage} alt="Loader" />

					</div>
				)}

				<div className="df-bg-primary df-d-flex df-flex-column df-nav-sidebar df-overflow-auto"
				>

					<div
					>


						<div className="df-d-flex df-rounded df-w-25 df-p-4"
						>

							<img className="df-w-100 df-rounded" src="assets/images/Group.svg"
							>
							</img>

						</div>



					</div>


					<div className="df-flex-grow-1"
					>

						<ToggleWrapper className="d-dropdown df-w-100 d--open"
						>

							<div className="d-dropdown-list df-bg-transparent df-position-static" role="d-toggle-wrapper"
							>
							</div>

						</ToggleWrapper>





						<Link style={{ "padding-left": "1rem" }} className="d-inline-block df-w-100 df-d-flex df-text-white df-text-decoration-none df-p-3 df-ps-5" target="_self" to="/chathistory" type="spa"
						>
							<img className=""
								style={{ "height": "24px", "width": "24px" }}
								src='assets/images/history.svg' alt="Logo" />


							<span className="df-ms-3"
							>
								Chat History
							</span>

						</Link>
						
						<Link style={{ "padding-left": "1rem" }} className="d-inline-block df-w-100 df-d-flex df-text-white df-text-decoration-none df-p-3 df-ps-5" target="_self" to="/allprediction" type="spa"
						>
							<img className=""
								style={{ "height": "24px", "width": "24px" }}
								src='assets/images/history.svg' alt="Logo" />


							<span className="df-ms-3"
							>
								ALL Prediction
							</span>

						</Link>
					
						<Link style={{ "padding-left": "1rem" }} className="d-inline-block df-w-100 df-d-flex df-text-white df-text-decoration-none df-p-3 df-ps-5" target="_self" to="/info" type="spa"
						>

							<img className=""
								style={{ "height": "24px", "width": "24px" }}
								src='assets/images/chat.svg' alt="Logo" />
							<span className="df-ms-3"
							>
								Chat
							</span>

						</Link>

					</div>
					<div
					>
						<div
						>
							<a className="d-inline-block df-w-100 df-d-flex df-align-items-center df-text-decoration-none df-text-white df-p-3" href="#" onClick={() => handleLogout()} target="_self" type="external"
							>

								<img className=""
									style={{ "height": "24px", "width": "24px" }}
									src='assets/images/logout.png' alt="Logo" />
								<span className="df-ms-3"
								style={{marginLeft:"0.5rem"}}
								>
									Logout
								</span>
							</a>
						</div>
					</div>
					
					<div
					>
						<div
						>
							<a className="d-inline-block df-w-100 df-d-flex df-align-items-center df-text-decoration-none df-text-white df-p-3" href="#/setting" target="_self" type="external"
							>

								<i className="fas fa-cog"
								>
								</i>
								<span className="df-ms-3"
								>
									Settings
								</span>

							</a>
						</div>
					</div>
					<div
					>
						<div
						>

							<a className="d-inline-block df-w-100 df-d-flex df-align-items-center df-text-decoration-none df-text-white df-p-3" href="" target="_self" type="external"
							>

<img className="" style={{ "height": "24px", "width": "24px","border-radius": "50%" ,position:"relative",left:"-4px" }} src='assets/images/profile.jpg' alt="Logo" />
								<span className="df-ms-3" style={{marginLeft:"0.5rem"}}>
								{username}
								</span>
							</a>
						</div>
					</div>
				</div>
				<div className="df-flex-grow-1 d-flex df-d-flex df-flex-column"
				>



					{!messageSent && messages.length === 0 && (
						<Fragment>
							<div className="d-container"
							>

								<img className="Image_5" id="irb07" src="https://dittofipublicfiles.s3.us-west-2.amazonaws.com/7473/30234-welcome.svg"
								>
								</img>


								<h1 className="Heading_2" id="ibwd3"
								>
									Welcome {username}
								</h1>

							</div>
							<div id="i8min"
								style={{ "margin-left": "20px" }}
							>

								<p className="Paragraph_1" id="icocs"
								>
									Please share how we can assist you by typing your inquiry. We're here to help and ready to respond once you start typing. Feel free to ask questions related to your projects, organization, or any public information that could aid you in your research.
								</p>

							</div>


							<div className="d-row Columns" id="iyceb" layout="6/6"
							>

								<div className="d-col d-col-6 d-col-medium-12 d-col-small-12 d-col-tiny-12 Column" id="i0mcw"
									style={{ "height": "45px" }}
								>

									<div id="i5eih"
									>

										<p className="Paragraph_2" id="io0j3"
										>
											What’s the standard process our organisation is following.
										</p>

									</div>

								</div>


								<div className="d-col d-col-6 d-col-medium-12 d-col-small-12 d-col-tiny-12 Column" id="i20fl"
									style={{ "height": "45px" }}
								>

									<div id="irtu8"
									>

										<p className="Paragraph_2" id="ia6t5"
										>
											What’s the standard process our organisation is following.
										</p>

									</div>

								</div>



							</div>



							<div className="d-row Columns_1" id="iq9oz" layout="6/6"
							>

								<div className="d-col d-col-6 d-col-medium-12 d-col-small-12 d-col-tiny-12 Column" id="i5tfa"

									style={{ "height": "45px" }}>

									<div id="i84jy"
									>

										<p className="Paragraph_2" id="isszs"
										>
											What’s the standard process our organisation is following.
										</p>

									</div>

								</div>


								<div className="d-col d-col-6 d-col-medium-12 d-col-small-12 d-col-tiny-12 Column" id="i3uqo"
									style={{ "height": "45px" }}
								>

									<div id="ira6u"
									>

										<p className="Paragraph_2" id="i4zxi"
										>
											What’s the standard process our organisation is following.
										</p>

									</div>

								</div>

							</div>
						</Fragment>
					)}

					<div className="messages-container df-p-3 df-overflow-auto" style={{ height: "90vh", bottom: "50px", overflow: "scroll", overflowX: "hidden", scrollbarWidth: "none" }}>
						{/* Displaying chat messages */}
						{messages.map((msg, index) => (
							<Fragment key={index}>
								{/* Display sent message */}
								<div className="df-d-flex df-align-items-start df-mb-3">
									<div className="" style={{ "border-radius": "50%" }}>
										<img className="" style={{ "height": "40px", "width": "40px" }} src='assets/images/profile.jpg' alt="Logo" />
										<span >{username}</span>
									</div>
									<div className="df-p-3 df-rounded df-bg-warning df-flex-grow-1" style={{ padding: "10px" }}>
										<span>{msg.sentMessage}</span>
									</div>
								</div>
								{/* Display response text */}
								<div className="df-d-flex df-align-items-start df-flex-row-reverse df-mb-3">
									<div className="df-p-2 df-rounded df-bg-primary df-text-white df-ms-2" style={{ "border-radius": "50%", "background-color": "#044856", "color": "black" }}>
										<img className="" style={{ "height": "24px", "width": "24px" }} src='assets/images/rag.svg' alt="Logo" />
									</div>
									<span style={{ verticalAlign: "bottom", top: "7px", position: "relative", left: "4px" }}>RAG</span>
									<div className="df-p-3 df-rounded df-flex-grow-1 df-bg-danger df-text-white" style={{ "color": "black",paddingLeft:"30px" }}>
        {/* Render response text as bullet points */}
        <ul style={{ margin: 0, padding: 0 }}>
            {msg.responseText.split('\n').map((line, index) => (
                // Check if line is not empty before rendering as list item
                line.trim() !== '' && <li key={index}>{line}</li>
            ))}
        </ul>
    </div>
								</div>
								<div style={{
									display: "flex",
									position: "relative",
									top: "-16px",
									left: "-85px",
									justifyContent: "flex-end"

								}}>
									  <img className="profile" onClick={() => handleCopy(msg.responseText)} alt="profile" src="assets/images/copy.png" style={{cursor:"pointer", marginTop: "10px" , height: "25px", width: "25px", marginLeft:"10px" }} />
									{/* <button onClick={() => handleCopy(msg.responseText)} style={{ height: "30px", cursor: "pointer", marginTop: "10px" }}>Copy</button> */}
									<div style={{
										marginTop: "8px"
									}}>
										<img onClick={handleThumbsUp}className="profile" alt="profile" src="assets/images/thumbs-up.png" style={{ height: "25px", width: "25px", marginLeft: "10px",cursor:"pointer" }} />


<img
    className="profile"
    onClick={handleThumbsDown}
    alt="profile"
    src="assets/images/thumbs-down.png"
    style={{ height: "20px", width: "20px", marginLeft: "10px", marginTop: "10px",cursor:"pointer" }}
/>

									</div>
									{/* {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
		  <span className="close" onClick={closeModal}>
              &times;
            </span>
		  <h2>Additional Feedback</h2>
		  <div className="box">
         
          <p>Answer Is Not Relevant</p>
        </div>
        <div className="box">
         
          <p>Some Content Is Missing</p>
        </div>
        <div className="box">
         
          <p>Not Meets The Requried Output</p>
        </div>
        <div className="box">
        
          <p>Wrong Resopnce </p>
        </div>
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      )} */}
	

								</div>
							</Fragment>
						))}
						<div ref={messagesEndRef} /> {/* Ref to the bottom of the messages container */}
					</div>






					<div className="DivBlock_3" id="i7q9f" style={{ height: "45px", borderRadius: "10px", bottom: "5px", position: "fixed", width: "75%" }}
					>

						{/* <input
									className="d-input df-m-0 df-me-2 df-rounded"
									placeholder="Type your message here"
									type="text"
									value={message}
									onChange={handleChange}

								/> */}
						<input
							className="d-input df-m-0 df-me-2 df-rounded"
							style={{ borderRadius: "10px", borderColor: "white" }}
							placeholder="Type your message here"
							type="text"
							value={message}
							onChange={handleChange}
							onKeyPress={(e) => {
								if (e.key === 'Enter') {
									handleSendMessage();
								}
							}}
						/>


						<img className="Image_6" style={{ "cursor": "pointer", right: "4px", height: "30px", width: "30px", top: "5px", position: "relative" }} id="ix86s" onClick={handleSendMessage} src="https://dittofipublicfiles.s3.us-west-2.amazonaws.com/7473/30235-image (7).png"
						>
						</img>

					</div>

				</div>
				{showSuggestions && (
                    <div className="suggestions-modal">
                        <div className="suggestions">
						<div>
						<h6 style={{
							width:"80%"
						}}>Additional Feedback</h6>
						<span className="close-modal" onClick={closeModal} style={{
							cursor:"pointer"
						}}>
              &times;
            </span>

						</div>
						{positiveSuggestions ? (
							// Render positive suggestions if positiveSuggestions state is true
							<>
								<div className="suggestion" onClick={() => handleSelectSuggestion("Good job!")}>
									Good job!
								</div>
								<div className="suggestion" onClick={() => handleSelectSuggestion("Very helpful!")}>
									Very helpful!
								</div>
							</>
						) :  (
							
							<>
								<div className="suggestion" onClick={() => handleSelectSuggestion("Answer Is Not Relevant")}>
									Answer Is Not Relevant
								</div>
								<div className="suggestion" onClick={() => handleSelectSuggestion("Some Content Is Missing")}>
									Some Content Is Missing
								</div>
								<div className="suggestion" onClick={() => handleSelectSuggestion("Not Meets The Required Output")}>
									Not Meets The Required Output
								</div>
							</>
						)}
				
						
                           
							{isModalOpen && (
  <div className="modal-overlay">
	
    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
    
      {/* Comment input */}
      {/* <input
        type="text"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        placeholder="Enter your comment"
        className="comment-input"
      /> */}
	  <input type="text" value={selectedSuggestion}
	  className="comment-input"
	  readOnly
/>
<div>
<input
    type="text"
    value={commentValue}
    onChange={handleCommentChange}
    placeholder="Enter your comment"
    className="comment-input"
style={{
	width:"90%"
}}
/>
    
	  <img  onClick={handleSendComment} style={{cursor:"pointer",position:"relative",height:"25px",width:"25px"}} alt="Frame" src="assets/images/send.svg"  />
</div>

    </div>

  </div>
)}
                        </div>
		
                    </div>
                )}
		

			</div>


		</ErrorBoundary>
	);
}

const mapStateToProps = function (state) {
	return state.reducer
}

export default connect(mapStateToProps, null)(Container);